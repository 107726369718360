import "./Footer.scss";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import logo from "../Header/images/logo.svg";
import telegram from "../Header/images/telegram.svg";
import email from "./email.svg";
import lifecell from "./lifecell.svg";
import kyivstar from "./kyivstar.svg";
import vodafone from "./vodafone.svg";

function Footer() {
	const { t } = useTranslation();

	return (
		<footer className="footer">
			<div className="container">
				<div className="footer__content">
					<div className="footer__content-items">
						<div className="footer__content-items__urls">
							<div className="footer__content-items__urls-logo">
								<NavLink to="/">
									<img src={logo} alt="Golden Line" />
								</NavLink>
							</div>
							<div className="footer__content-items__urls-nav">
								<nav>
									<div className="footer__content-items__urls-nav__first">
										<NavLink
											to="/rate"
											className={({ isActive }) => (isActive ? "active" : "")}
										>
											{t("menuitem1")}
										</NavLink>
										<NavLink
											to="/services"
											className={({ isActive }) => (isActive ? "active" : "")}
										>
											{t("menuitem2")}
										</NavLink>
										<NavLink
											to="/why-us"
											className={({ isActive }) => (isActive ? "active" : "")}
										>
											{t("menuitem3")}
										</NavLink>
									</div>
									<div className="footer__content-items__urls-nav__first">
										<NavLink
											to="/how-to"
											className={({ isActive }) => (isActive ? "active" : "")}
										>
											{t("menuitem4")}
										</NavLink>
										<NavLink
											to="/contacts"
											className={({ isActive }) => (isActive ? "active" : "")}
										>
											{t("menuitem5")}
										</NavLink>
										{/* Добавляем ссылки на PDF-файлы */}
										<a href="/oferta_comunicat.pdf" target="_blank" rel="noopener noreferrer">
										{t("ofertacomunicat")}
										</a>
										<a href="/oferta_po.pdf" target="_blank" rel="noopener noreferrer">
										{t("ofertapz")}
										</a>
									</div>
								</nav>
							</div>
						</div>
						<div className="footer__content-items__contact">
							<div className="footer__content-items__contact-items">
								<div className="footer__content-items__contact-items__left">
									<button>
										<a href="tel:+380638899090">
											<img src={lifecell} alt="Lifecell" />
											+38 063 889 90 90
										</a>
									</button>
									<button>
										<a href="tel:+380678899090">
											<img src={kyivstar} alt="Kyivstar" />
											+38 067 889 90 90
										</a>
									</button>
									<button>
										<a href="tel:+380508899090">
											<img src={vodafone} alt="Vodafone" />
											+38 050 889 90 90
										</a>
									</button>
								</div>
								<div className="footer__content-items__contact-items__right">
									<button>
										<a href="https://t.me/test">
											<img src={telegram} alt="Telegram" />
											http//telegram
										</a>
									</button>
									<button>
										<a href="mailto:info@goldenline.in.ua">
											<svg
												width="20"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M12.9319 10.2797L19.0001 14.1159V6.28149L12.9319 10.2797Z"
													fill="#FDD85A"
												/>
												<path
													d="M1 6.28149V14.1159L7.0682 10.2797L1 6.28149Z"
													fill="#FDD85A"
												/>
												<path
													d="M17.8748 4H2.12493C1.56356 4 1.11807 4.4185 1.03369 4.95737L9.99987 10.8647L18.9661 4.95737C18.8817 4.4185 18.4362 4 17.8748 4Z"
													fill="#FDD85A"
												/>
												<path
													d="M11.9013 10.9594L10.3094 12.0079C10.2149 12.0697 10.1081 12.1001 10.0001 12.1001C9.89207 12.1001 9.78519 12.0697 9.69069 12.0079L8.09883 10.9583L1.03613 15.4256C1.12276 15.96 1.566 16.3751 2.12512 16.3751H17.875C18.4341 16.3751 18.8774 15.96 18.964 15.4256L11.9013 10.9594Z"
													fill="#FDD85A"
												/>
											</svg>
											info@goldenline.in.ua
										</a>
									</button>
                  <p>пн-пт 9:00-18:00</p>
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</div>
      <div className="copyright">
								<p>Golden Line © 2024. All rights reserved.</p>
							</div>
		</footer>
	);
}

export default Footer;
