import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
	ua: {
		translation: {
			ofertapz: "Оферта програмне забезпечення",
			ofertacomunicat: "Оферта комунікаційні послуги",
			modalh1: "Записуйся на безкоштовне тестування",
			modalinput: "@Телеграм",
			modalinputerror: "Помилка!",
			modalinputerror2: "Щось пiшло не так!",
			modalinputok: "Дякую! Ми зв'яжемося з вами найближчим часом!",
			modaldisclaimer: "Ознайомитися з умовами безкоштовного періоду",
			menuitem1: "Тарифи",
			menuitem2: "Послуги",
			menuitem3: "Чому ми",
			menuitem4: "Як підключити",
			menuitem5: "Контакти",
			menuitem6: "Гаряча лінія",
			h1: "Надійна лінія комунікацій через",
			h1__2: "IP-телефонію",
			firstsectioncontentheadersub:
				"Масштабуй свій бізнес за допомогою професійно налагодженої телефонії",
			firstsectioncontentheadersub2:
				"Безкоштовний пробний період та знижки новим клієнтам",
			firstsectioncontentbtn: "Зв'язатися",
			firstsectioncontentbtn2: "Послуги",
			secondsectionh2: "Наші послуги",
			secondsectionh2__p: "та переваги для бізнесу",
			secondsectionh2__p2:
				"Наші послуги з IP-телефонії надають вашому бізнесу широкі можливості для покращення комунікацій та оптимізації робочих процесів.",
			secondsectionh2__p3:
				"Ми пропонуємо сучасні рішення, що допомагають знизити витрати, підвищити ефективність роботи та забезпечити високоякісний зв'язок.",
			secondsectionh2__p4:
				"Завдяки нашим інноваційним технологіям та гнучким тарифним планам, ви зможете покращити обслуговування клієнтів, автоматизувати процеси та забезпечити безперебійну роботу вашої команди.",
			secondsectiontabsservices: "Основні переваги:",
			secondsectiontabs1: "IP-телефонія",
			secondsectiontabs2: "Мобільні номери",
			secondsectiontabs3: "CRM система",
			secondsectiontabs4: "Call Tracking",
			secondsectiontabs5: "Мовна аналітика з ШІ",
			secondsectiontabscontent1header: "IP-телефонія",
			secondsectiontabscontent1header2:
				"Забезпечує високоякісний звʼязок зі збереженням всієї історії та записів розмов із клієнтами.",
			secondsectiontabscontent1text1:
				"<strong>Зниження витрат</strong> на зв'язок порівняно з традиційною телефонією.",
			secondsectiontabscontent1text11:
				"<strong>IP-телефонія</strong> — це сучасна технологія, яка дозволяє передавати голосові дзвінки через інтернет або інші IP-мережі. Вона забезпечує високоякісний зв'язок без використання традиційних телефонних ліній. IP-телефонія потрібна для оптимізації бізнес-процесів, зниження витрат на зв'язок та підвищення мобільності працівників. ",
			secondsectiontabscontent1text12:
				"Завдяки можливостям інтеграції з CRM-системами та іншими бізнес-додатками, вона допомагає поліпшити обслуговування клієнтів, ефективніше керувати викликами та аналізувати їхню ефективність. Це робить IP-телефонію незамінним інструментом для сучасного бізнесу.",
			secondsectiontabscontent1text2:
				"<strong>Мобільність і гнучкість</strong> — можливість використовувати телефонний номер незалежно від географічного розташування.",
			secondsectiontabscontent1text3:
				"<strong>Інтеграція</strong> з іншими системами — CRM, віртуальними АТС та іншими.",
			secondsectiontabscontent2header: "Мобільні номери",
			secondsectiontabscontent2header2:
				"Телефонні номери використовуються для здійснення та прийому дзвінків через інтернет.",
			secondsectiontabscontent2text11:
				"<strong>Мобільні номери</strong> — це телефонні номери, які використовуються для здійснення та прийому дзвінків через інтернет, що забезпечує мобільність і гнучкість зв'язку. Вони дозволяють користувачам бути на зв'язку незалежно від їхнього місцезнаходження, використовуючи будь-який пристрій з доступом до інтернету. ",
			secondsectiontabscontent2text12:
				"Мобільні номери особливо корисні для бізнесу, оскільки дозволяють зберігати єдиний контактний номер під час переїздів, розширення офісів або роботи з дому, що сприяє підвищенню ефективності роботи та покращенню обслуговування клієнтів.",
			secondsectiontabscontent2text1:
				"<strong>Доступність:</strong> можливість прийому і здійснення дзвінків через IP-телефонію навіть при відсутності мобільного зв'язку, використовуючи інтернет.",
			secondsectiontabscontent2text2:
				"<strong>Мобільність і гнучкість</strong> — можливість використовувати телефонний номер незалежно від географічного розташування.",
			secondsectiontabscontent3header: "CRM-система",
			secondsectiontabscontent3header2:
				"Дозволяє автоматично зберігати дані про дзвінки, записувати розмови, відстежувати історію спілкування та аналізувати ефективність комунікацій. ",
			secondsectiontabscontent3text11:
				"<strong>CRM-система (Customer Relationship Management)</strong> — це інструмент для управління взаємодією з клієнтами, який інтегрується з телефонною системою компанії. Вона дозволяє автоматично зберігати дані про дзвінки, записувати розмови, відстежувати історію спілкування та аналізувати ефективність комунікацій. ",
			secondsectiontabscontent3text12:
				"Використання CRM-системи допомагає підвищити рівень обслуговування клієнтів, швидше реагувати на їхні запити та пропозиції, покращувати внутрішні бізнес-процеси та підвищувати продуктивність команди. Це рішення є ключовим для компаній, що прагнуть ефективно керувати взаємовідносинами з клієнтами і збільшувати продажі.",
			secondsectiontabscontent3text1:
				"<strong>Інтеграція дзвінків:</strong> можливість інтеграції IP-телефонії для автоматичного збереження записів розмов та даних про дзвінки в CRM.",
			secondsectiontabscontent3text2:
				"<strong>Зручність управління:</strong> автоматизація та покращення роботи з клієнтами через вбудовані функції IP-телефонії (запис дзвінків, автоматичний розподіл викликів).",
			secondsectiontabscontent4header: "Call Tracking",
			secondsectiontabscontent4header2:
				"Технологія, яка дозволяє відстежувати джерела дзвінків та аналізувати ефективність реклами.",
			secondsectiontabscontent4text11:
				"<strong>Call Tracking</strong> — це технологія, яка дозволяє відстежувати та аналізувати вхідні телефонні дзвінки. Вона допомагає визначати джерела дзвінків, такі як рекламні кампанії, веб-сайти або інші маркетингові канали. ",
			secondsectiontabscontent4text12:
				"Використання CRM-системи допомагає підвищити рівень обслуговування клієнтів, швидше реагувати на їхні запити та пропозиції, покращувати внутрішні бізнес-процеси та підвищувати продуктивність команди. Це рішення є ключовим для компаній, що прагнуть ефективно керувати взаємовідносинами з клієнтами і збільшувати продажі.",
			secondsectiontabscontent4text1:
				"<strong>Аналіз ефективності реклами:</strong> можливість аналізу ефективності рекламних кампаній та інших джерел дзвінків.",
			secondsectiontabscontent4text2:
				"<strong>Підвищення конверсії:</strong> розуміння, звідки надходять дзвінки, допомагає оптимізувати маркетингові стратегії і покращувати обслуговування клієнтів.",
			secondsectiontabscontent5header: "Мовна аналітика з ШІ",
			secondsectiontabscontent5header2:
				"Цифровий асистент, який швидко обробить дані та зробить на їх основі корисні висновки. ",
			secondsectiontabscontent5text11:
				"<strong>Телефонія з використанням ШI</strong> — це майбутнє бізнес-комунікацій і актуальна конкурентна перевага. Вона забезпечує не лише якісний зв'язок та інформацію про дзвінки, але й надає вам цифрового асистента, який швидко обробить дані та зробить на їх основі корисні висновки. ШI стане незамінним помічником для відділів продажів, підтримки та навіть маркетингу.",
			secondsectiontabscontent5text12:
				"Завдяки можливостям інтеграції з CRM-системами та іншими бізнес-додатками, вона допомагає поліпшити обслуговування клієнтів, ефективніше керувати викликами та аналізувати їхню ефективність. Це робить IP-телефонію незамінним інструментом для сучасного бізнесу.",
			secondsectiontabscontent5text1:
				"<strong>Економія часу:</strong> Golden Line ШІ автоматично створює текстові розшифровки дзвінків та короткі резюме розмов. Тепер вам не потрібно витрачати години на прослуховування дзвінків.",
			secondsectiontabscontent5text2:
				"<strong>Зосередження на ключових моментах:</strong> через обмежений час і великий обсяг даних аналіз часто проводиться вибірково, що може призвести до втрати важливої інформації. ШІ допоможе виділити ключові моменти, які заслуговують на увагу.",
			secondsectiontabscontent5text3:
				"<strong>Рекомендації для укладання угод:</strong>  ваш цифровий асистент проаналізує розмову і надасть практичні поради щодо подальших дій, щоб максимально наблизити укладання угоди з клієнтом.",
			thirdsectionh2: "Тарифи",
			thirdsectionh2p: "що ми пропонуємо",
			thirdsectionp: "Тариф",
			thirdsectionp1: "бюджетний ",
			thirdsectionp2: "розширений  ",
			thirdsectionp3: "максимальний ",
			thirdsectionh2AI: "Мовна аналітика",
			thirdsectionh2p1AI: "на основі ШI",
			thirdsectionh2p2AI:
				"Максимально використовуйте дзвінки завдяки штучному інтелекту – вашому надійному помічнику у закритті угод.",
			thirdsectionh2p3AI:
				"Сьогодні навички роботи з ШІ надають перевагу, але вже завтра вони стануть обов’язковими для професійної діяльності. Впровадьте інструмент, який підвищить ефективність ваших відділів і суттєво вплине на зростання продажів у вашій компанії вже сьогодні.",
			thirdsectionh2p4AI:
				"Головна мета ШІ від Golden Line — зробити вашу роботу та діяльність ваших співробітників більш легкою і продуктивною.",
			thirdsectionblockp1:
				"Ідеально підходить для невеликих компаній або стартапів. Він включає базовий набір функцій для голосового зв'язку, обмежену кількість дзвінків і основну підтримку.",
			thirdsectionblockp2:
				"Оптимальне рішення для середнього бізнесу, що потребує більше можливостей для управління зв'язком та інтеграції з іншими бізнес-системами.",
			thirdsectionblockp3:
				"Призначений для великих компаній, які потребують максимальних можливостей IP-телефонії, включаючи необмежену кількість дзвінків та розширені функції безпеки.",
			thirdsectionblockbtntext: "Детальніше",
			foursectionh2: "Принцип роботи",
			foursectionh2p: "основні пристрої для використання IP-телефонії:",
			foursectionp1: "IP-телефон",
			foursectionblockp1:
				"Спеціальний телефон, який підключається до інтернету та використовує IP для передачі голосу.",
			foursectionp2: "Інтернет-маршрутизатор",
			foursectionblockp2:
				"Пристрій для підключення до інтернету та створення мережі для IP-телефонів.",
			foursectionp3: "Комп’ютер або мобільний пристрій",
			foursectionblockp3:
				"Використовуються для софтфонів, що дозволяють здійснювати дзвінки через спеціальні програми.",
			foursectionp4: "VoIP-шлюз або адаптер",
			foursectionblockp4:
				"Перетворює сигнал від традиційних телефонів або підключає IP-телефонію до звичайних телефонних мереж.",
			fivesectionh4: "Залишай заявку",
			fivesectionh4p: "та отримуй безкоштовний тестовий період",
			fivesectionplaceholder1: "ПІБ",
			fivesectionplaceholder2: "Номер телефону",
			fivesectionplaceholder3: "Електронна пошта",
			fivesectionplaceholder4: "Назва Вашого бізнесу",
			fivesectioncontentbtn: "Відправити",
			ratepageupheadertext1: "Знижка 30% на перший місяць користування тарифом 'розширений'",
			ratepageupheadertext2: "Обирайте тариф 'максимальний' та отримуйте знижку -40% на перший місяць",
			ratepageupheadertext3: "Оплатіть одразу за 2 місяці та отримайте додатковий місяць абсолютно безкоштовно. ",
			ratepageheadertext: "Тарифи",
			ratepageheaderbtnname1: "₴ 350",
			ratepageheaderbtnname11: " /міс",
			ratepageheaderbtnname2: "₴ 600",
			ratepageheaderbtnname22: " /міс",
			ratepageheaderbtnname3: "₴ 1600",
			ratepageheaderbtnname33: " /міс",
			ratepageheaderbtnnamerate1: "бюджетний",
			ratepageheaderbtnnamerate2: "розширений",
			ratepageheaderbtnnamerate3: "максимальний",
			ratepagetablestroke1: "Підключення та налаштування Віртуальної АТС",
			ratepagetablestroke1_1:
				"Наші спеціалісти забезпечують швидкий запуск системи, професійне налаштування під конкретні бізнес-потреби й розкажуть, як використовувати Golden Line максимально зручно й ефективно.",
			ratepagetablestroke2: "Підключення власного номеру телефону",
			ratepagetablestroke2_1:
				"До віртуальної АТС Golden Line можна підключити номери, які ваша компанія вже використовує",
			ratepagetablestroke3: "Кількість робочих місць",
			ratepagetablestroke3_1:
				"Це число визначає, скільки співробітників можуть мати доступ до телефонного зв'язку для здійснення та прийому дзвінків.",
			ratepagetablestroke4: "Запис телефонних розмов",
			ratepagetablestroke4__1: "500 хв",
			ratepagetablestroke4__2: "10 000 хв",
			ratepagetablestroke4__3: "100 000 хв",
			ratepagetablestroke4_1:
				"Усі домовленості менеджерів із клієнтами зафіксовані. Співробітники працюють ефективніше. Значно швидше та простіше вирішувати спірні питання й навчати нових співробітників.",
			ratepagetablestroke5: "Запобігання не прийнятим дзвінкам",
			ratepagetablestroke5_1:
				"Ви не втрачаєте клієнтів. Усі вхідні звернення автоматично фіксуються та контролюються. У вас немає забутих і втрачених викликів навіть у позаробочий час та на вихідних.",
			ratepagetablestroke51: "до 3",
			ratepagetablestroke52: "до 6",
			ratepagetablestroke53: "до 10",
			ratepagetablestroke6: "Історія телефонних дзвінків",
			ratepagetablestroke6_1:
				"Ви можете бачити повну картину спілкування співробітників із клієнтами",
			ratepagetablestroke7: "Оптимізація витрат на вихідний звязок",
			ratepagetablestroke7_1:
				"Система аналізує напрямок вашого дзвінка й автоматично підбирає той телефонний номер (міський, мобільний, міста іншої країни), з якого найвигідніше здійснювати дзвінок.",
			ratepagetablestroke8: "Сценарії прийому дзвінків за часом",
			ratepagetablestroke8_1:
				"Ви обираєте найзручніший варіант приймання дзвінків з урахуванням специфіки вашого бізнесу та графіку роботи компанії",
			ratepagetablestroke81: "+ декілька сценарієв",
			ratepagetablestroke9: "Мобільні телефонні номери",
			ratepagetablestroke9_1:
				"До віртуальної АТС Golden Line можна підключити нові номери будь-якого мобільного оператора",
			ratepagetablestroke10: "Міські телефонні номери",
			ratepagetablestroke10_1:
				"До віртуальної АТС Golden Line можна підключити будь-які міські та міжнародні номери",
			ratepagetablestroke11: "Особистий кабінет співробітника",
			ratepagetablestroke11_1:
				"Ви матимете особистий кабінет, у якому зберігаються дані про всі дзвінки: їх загальна кількість, тривалість, записи розмов, список пропущених викликів, докладна аналітика тощо.",
			ratepagetablestroke12: "Безкоштовні дзвінки між співробітниками",
			ratepagetablestroke12_1:
				"Віртуальна АТС Golden Line працює через Інтернет, тому внутрішній зв'язок для всіх співробітників безкоштовний незалежно від їх місцезнаходження.",
			ratepagetablestroke121: "Ідентифікація за номером",
			ratepagetablestroke122: "Ідентифікація клієнта за ім’ям",
			ratepagetablestroke123:
				"Розширена історія комунікації з клієнтом під час дзвінка",
			ratepagetablestroke13: "Доступ до Бази знань (інструкції)",
			ratepagetablestroke13_1:
				"Доступ до централізованої бази знань полегшує навчання нових працівників, забезпечуючи їх необхідною інформацією для ефективної роботи.",
			ratepagetablestroke14: "Паркування виклику",
			ratepagetablestroke14_1:
				"Можливість ставити активний виклик на утримання та дає змогу продовжити розмову з іншого телефона в організації",
			ratepagetablestroke15: "Контакти",
			ratepagetablestroke15_1:
				"Можливість ідентифікувати клієнта під час дзвінка.",
			ratepagetablestroke16: "Переведення дзвінка (call transfer)",
			ratepagetablestroke16_1:
				"Можливість швидко направити клієнта до потрібного спеціаліста, що покращує якість обслуговування та скорочує час вирішення запиту.",
			ratepagetablestroke17: "Перехоплення дзвінка (call pickup)",
			ratepagetablestroke17_1:
				"Можливість швидко відповісти на вхідний виклик, коли основний оператор зайнятий або відсутній, забезпечуючи безперервність обслуговування та мінімізуючи час очікування клієнтів.",
			ratepagetablestroke171: "1 сценарій",
			ratepagetablestroke172: "Необмежені сценарії",
			ratepagetablestroke18: "Переадресація виклику",
			ratepagetablestroke18_1:
				"Можливість переадресувати дзвінки на інший номер або пристрій, що забезпечує безперервний зв'язок та доступність, навіть коли основний абонент недоступний або зайнятий.",
			ratepagetablestroke19: "Інтерактивне голосове меню — IVR",
			ratepagetablestroke19__2: "однорівневий",
			ratepagetablestroke19__3: "багаторівневий",
			ratepagetablestroke19_1:
				"Завдяки компетентному налаштуванню меню IVR співробітникам не потрібно приймати безліч однотипних вхідних дзвінків і постійно переводити абонентів на різних співробітників.",
			ratepagetablestroke191: "5 ліній",
			ratepagetablestroke20: "Автоматичне розподілення дзвінків",
			ratepagetablestroke20__2: "сценарій, черга",
			ratepagetablestroke20__3: "сценарій, черга, черга зі сповіщенням, розширений сценарій",
			ratepagetablestroke20_1:
				"Значно краще розподіляти дзвінки від клієнтів на групу співробітників, а не лише на один апарат. Таким чином приймати дзвінки стане простіше, а час на відповідь клієнту зменшиться. Пропущених викликів стане менше. А головне - співробітники припинять передавати один одному мобільні телефони, тож ви забудете про хаос з телефонами.",
			ratepagetablestroke201: "Покращена",
			ratepagetablestroke202: "Максимальна",
			ratepagetablestroke21: "Підтримка",
			ratepagetablestroke21_2: "протягом робочого дня",
			ratepagetablestroke21_3: "протягом 1 години",
			ratepagetablestroke21_1:
				"Швидке вирішення технічних питань і оперативна допомога, що забезпечує безперебійне функціонування Віртуальної АТС Golden Line",
			ratepagetablestroke22: "Налаштування IP-телефону",
			ratepagetablestroke22_1:
				"Налаштуваннями обладнання займаються наші спеціалісти для економії вашого часу, уникнення стресу від самостійного налаштування, гарантії правильної роботи обладнання з першого разу, а також швидкого та безперебійного користування",
			ratepagetablestroke23: "Інтеграція з СRМ Golden Line",
			ratepagetablestroke23_1:
				"Об'єднання телефонії та CRM в одному сервісі покращує ефективність та якість обслуговування клієнтів. Зручно інтегровані дані про клієнтів, автоматичне ведення історії дзвінків, швидкий доступ до інформації під час розмови, автоматизації процесів та підвищення продуктивності працівників. Така інтеграція також спрощує аналітику та управління взаємодіями з клієнтами.",
			ratepagetablestroke24: "API",
			ratepagetablestroke24_1:
				"Завдяки відкритим API Golden Line можна легко інтегрувати телефонію з іншими бізнес-системами, такими як CRM, ERP або системи підтримки клієнтів. Це дозволяє автоматично зберігати інформацію про дзвінки, обмінюватися даними між системами та покращувати загальну ефективність бізнес-процесів",
			ratepagetablestroke25: "SMS/Viber розсилки",
			ratepagetablestroke25_1:
				"Швидкий і зручний спосіб надсилати SMS-повідомлення клієнту. Співробітники можуть надсилати SMS клієнтам безпосередньо з особистого кабінету. Усього за допомогою двох кліків можна надіслати повідомлення з шаблонним або унікальним текстом.",
			ratepagetablestroke251: "Підтримка",
			ratepagetablestroke252: "Підтримка",
			ratepagetablestroke26: "Суфлер",
			ratepagetablestroke26_1:
				"Функція, за допомогою якої більш досвідчений співробітник слухає дзвінок колеги в реальному часі та допомогає за необхідності",
			ratepagetablestroke27: "Автообзвон",
			ratepagetablestroke27_1:
				"Автообзвон допомогає бізнесу автоматизувати процес зв'язку з клієнтами, що дозволяє швидко і ефективно охоплювати велику аудиторію, оповіщувати про важливі зміни, акції, спеціальні пропозиції, проводити опитування, нагадування та знижувати витрати на персонал.",
			ratepagetablestroke28: "Налаштування Автообзвону",
			ratepagetablestroke28_1:
				"Наші спеціалісти налаштують автообзвон під конкретно ваші бізнес-потреби й розкажуть, як використовувати його максимально зручно й ефективно.",
			ratepagetablestroke29:
				"Кількість запусків Автообзвону та Голосового робота",
			ratepagetablestroke29_1:
				"Це дозволяє одночасно обробляти більше дзвінків, підвищує ефективність, зменшує час очікування для клієнтів і забезпечує безперервну роботу навіть при високому навантаженні, що покращує якість обслуговування та задоволеність клієнтів.",
			ratepagetablestroke30: "Доступ до статистики Автообзвону",
			ratepagetablestroke30_1:
				"Ці дані можна використовувати для аналізу автообзвону та голосових роботів, щоб підвищити їхню результативність та задоволеність клієнтів. Також можна проаналізувати наскільки ефективний автообзвон у досягненні поставлених цілей, таких як залучення клієнтів або зростання продажів",
			ratepagetablestroke31: "Міграція з інших телефоній (перенос налаштувань)",
			ratepagetablestroke31_1:
				"Це дозволяє швидко та безболісно перейти на нову телефонну систему, зберігаючи всі існуючі налаштування, номери та правила, що мінімізує простої, скорочує час адаптації та зберігає бізнес-процеси без змін.",
			ratepagetablestroke32: "Обробник подій телефонії",
			ratepagetablestroke32_1:
				"Це дозволяє автоматично реагувати на різні події, такі як вхідні дзвінки, завершення викликів, залишені голосові повідомлення тощо. Це підвищує ефективність, автоматизує робочі процеси та покращує обслуговування клієнтів, забезпечуючи швидку та точну реакцію на події.",
			ratepagetablestroke33: "Кнопка зворотного дзвінка «GetCall» на сайт",
			ratepagetablestroke33_1:
				"GetCall - це розумний віджет для вашого сайту, який пропонує клієнту перетелефонувати за 30 секунд і автоматично з’єднує з ним менеджера. Збільшує кількість звернень із сайту до 50%",
			ratepagetablestroke34: "Інтеграція зі сторонньою СRM",
			ratepagetablestroke34_1:
				"Інтеграція телефонії Golden Line з Вашою CRМ-системою, завдяки чому вам доступна: Інформація про клієнта під час дзвінка; <br> Швидке створення картки клієнта під час вхідних викликів; <br> Прив'язка 'клієнт-співробітник'; <br> Набір номера одним кліком із картки клієнта в СRM; <br> Історія дзвінків клієнта в CRM-системі.",
			ratepagetablestroke35:
				"Персоналізована система голосової пошти з відправкою на email",
			ratepagetablestroke35_1:
				"Користувачі можуть миттєво дізнаватися про нові повідомлення, навіть якщо вони знаходяться поза офісом або не мають доступу до телефонної системи.",
			ratepagetablestroke36: "Мобільний додаток",
			ratepagetablestroke36_1:
				"Додаток, який встановлюється на робочі мобільні телефони співробітників і надає компанії готову статистику дзвінків і запис розмов. За обраний період часу, по конкретному співробітнику або групі співробітників (відділу).",
			ratepagetablestroke361: "є без email",
			ratepagetablestroke362: "є з email",
			ratepagetablestroke37: "Дзвінок одним натисканням 'Click to Call'",
			ratepagetablestroke37_1:
				"Усі номери телефонів в кабінеті та на сайтах стають клікабельними, завдячи чому ваші менеджери економлять час на наборі номерів",
			ratepagetablestroke38: "Управління переадресацією з особистого кабінету",
			ratepagetablestroke38_1:
				"Дозволяє користувачам самостійно та в режимі реального часу налаштовувати переадресацію дзвінків на інші номери чи пристрої, що забезпечує гнучкість, зручність, оперативність реагування на ситуації та підвищує доступність для клієнтів.",
			ratepagetablestroke39: "Модуль CallCenter",
			ratepagetablestroke39_1:
				"Більше можливостей під час обробки дзвінків: розумні правила розподілу вхідних викликів, статуси оператора, звіти й аналітика щодо всіх дзвінків, функція 'Супервайзер' тощо.",
			ratepagetablestroke40: "Конференц-зв'язок/Відеозв'язок",
			ratepagetablestroke40_1:
				"Дозволяє одночасно спілкуватися з кількома людьми, що зручно для обговорення питань у реальному часі. Також дозволяє швидко приймати рішення, залучаючи до обговорення всіх необхідних учасників.",
			ratepagetablestroke41: "Webhook",
			ratepagetablestroke41_1:
				"Доступ до програмного коду, за допомогою якого ви відслідковуєте зміни на одному сайті та передаються дані про це на інший сайт",
			ratepagetablestroke411: "Інтеграція з Вашим Asterisk",
			ratepagetablestroke411_1:
				"Якщо ви вже маєте власний Asterisk та бажаєте його інтегрувати - наші спеціалісти з задоволенням це для вас реалізують",
			ratepagetablestroke42: "Додаткові послуги",
			ratepagetablestroke421: "Ціна",
			ratepagetablestroke43:
				"Додаткова зовнішня лінія (телефонний SIP номер) поза тарифом",
			ratepagetablestroke431:
				"Додатковий канал зв'язку, який підключається до компанії для здійснення або прийому дзвінків. Це дозволяє приймати або здійснювати більше дзвінків одночасно, що особливо корисно для компаній із великою кількістю вхідних або вихідних дзвінків. Дозволяє компанії підтримувати більше зв'язків із зовнішніми клієнтами без необхідності переходу на інший тарифний план.	",
			ratepagetablestroke44:
				"Додаткове робоче місце поза тарифом",
			ratepagetablestroke441:
				"Можливість додати більше робочих місць або користувачів до телефонії, ніж передбачено в тарифному плані. Це дає можливість масштабувати телефонну інфраструктуру відповідно до потреб вашої компанії, наприклад, при розширенні штату без необхідності переходу на інший тарифний план.	",
			ratepagetablestroke45: "Оренда слоту для SIM карти",
			ratepagetablestroke451:
				"Мобільні номери підключаються до Віртуальної АТС за допомогою спеціального обладнання - GSM-шлюзу, у який установлюються SIM-карти. Ви можете орендувати місця (слоти) для SIM-карт у нашому обладнанні або придбати власний шлюз.",
			ratepagetablestroke46: "Запис голосового повідомлення (привітання) диктором",
			ratepagetablestroke461:
				"Голосове привітання від нашого штату професійних дикторів створює враження професіоналізму та надійності при дзвінку до вашої компанії. Приємний голос диктора може надати важливу інформацію або направити клієнтів до потрібного відділу, що економить час ваших співробітників. Також ваш клієнт одразу отримує інформацію про робочий графік компанії, що дозволяє йому зрозуміти, коли можна передзвонити або очікувати зворотного зв'язку. Це зменшує розчарування клієнтів, покращує їхній досвід взаємодії з компанією та знижує кількість пропущених можливостей для комунікації.	",
			ratepagetablestroke47: "Кнопка зворотного дзвінка «GetCall» на сайт",
			ratepagetablestroke471:
				"GetCall - це розумний віджет для вашого сайту, який пропонує клієнту перетелефонувати за 30 секунд і автоматично з’єднує з ним менеджера. Він збільшує кількість звернень із сайту до 50%. Відвідувачі стають вашими клієнтами 24/7, система автоматично з'єднує співробітників із клієнтами у точно зазначений час. Колір віджету підлаштовується під колір вашого сайту. ",
			ratepagetablestroke48: "SPAM-моніторинг телефонних номерів",
			ratepagetablestroke481:
				"Рішення для автоматичної перевірки телефонних номерів вашого бізнесу по СПАМ-базам популярних моніторингових систем. Це дозволяє: виявляти факти належності Ваших телефонних номерів як джерело СПАМу (бази: GetContact, TrueCaller, Hiya, Xiaomi); <br>проводити автоматичну перевірку телефонних номерів згідно з Вашим розкладом;<br> проводити автоматичну заміну телефонного номера, якому надано статус СПАМ;<br>підвищити кількість успішних дзвінків до ваших клієнтів.",
			ratepagetablestroke49: "Додатково 10 000 хвилин запису телефонних розмов",
			ratepagetablestroke491:
				"Можливість збільшити кількість запису телефонних розмов без зміни тарифного плану",
			ratepagetablestroke50: "Автообзвон",
			ratepagetablestroke501:
				"Автообзвон допомогає бізнесу автоматизувати процес зв'язку з клієнтами, що дозволяє швидко і ефективно охоплювати велику аудиторію, оповіщувати про важливі зміни, акції, спеціальні пропозиції, проводити опитування, нагадування та знижувати витрати на персонал.",
			ratepagetablestroke51_: "Тарифікація Автообзвону (посекундна)",
			ratepagetablestroke51_1:
				"Наші спеціалісти налаштують автообзвон під конкретно ваші бізнес-потреби й розкажуть, як використовувати його максимально зручно й ефективно. Оплата за тарифікацію посекундна.",
			ratepagetablestroke52_: "Мовна аналітика",
			ratepagetablestroke52_1:
				"Це інноваційний метод обробки голосових даних штучним інтелектом, спрямований на виявлення, класифікацію та вилучення цінної інформації з телефонних розмов. Перетворення звуку на текст, аналіз розмови штучним інтелектом, розпізнавання мови співрозмовників, аналіз телефонної розмови. Цей передовий інструмент високих технологій автоматизує аналіз телефонних розмов, перетворюючи їх на структуровані дані.	 ",
			ratepagetablestroke53_: "Шифрування TLS",
			ratepagetablestroke53_1:
				"Посилене шифрування за допомогою протоколу TLS (Transport Layer Security) забезпечує максимальну безпеку даних, конфіденційність та захист від будь-яких типів атак. Відповідає стандартам GDPR та HMAC.",
				ratepagetableprice1: "40 ₴/міс",
ratepagetableprice2: "100 ₴/міс",
ratepagetableprice3: "150 ₴/міс",
ratepagetableprice4: "від 500 ₴",
ratepagetableprice5: "100 ₴/міс",
ratepagetableprice6: "50 ₴",
ratepagetableprice7: "від 50 ₴",
ratepagetableprice8: "від 1000 ₴",
ratepagetableprice9: "від 0,50 ₴/хв",
ratepagetableprice10: "від 1,20 ₴/хв",
ratepagetableprice11: "40 ₴/внутр лінію",

			howtoh2: "Як підключити",
			howtoh3: "Віртуальну АТС Golden Line",
			howto1h4: "Обираємо тариф",
			howto1p1:
				"Порівняйте різні тарифні плани, запропоновані у вкладці Тарифи. Враховуйте кількість користувачів, необхідні функції та бюджет вашої компанії. Оберіть тариф, який найкраще відповідає вашим потребам. ",
			howto2h4: "Створюємо обліковий запис",
			howto1p2:
				"Зв’яжіться з нами та створіть обліковий запис. Увійдіть в систему та налаштуйте основні параметри: додайте співробітників, створіть віртуальні номери та налаштуйте базові функції.",
			howto3h4: "Підключаємо обладнання",
			howto1p3:
				"Підключіть необхідне обладнання, таке як IP-телефони, VoIP-шлюзи або адаптери. Переконайтеся, що всі пристрої налаштовані правильно та підключені до інтернету.",
			howto4h4: "Навчаємо співробітників",
			howto1p4:
				"Проведіть тестові дзвінки для перевірки роботи системи. Забеспечте співробітників усіма необхідними інструкціями щодо використання нової системи.",
			whyusheaderh2: "Дізнайтеся",
			whyusheaderp: "Чому обирають нас",
			whyusfirstblock1: "Гнучкі тарифні плани",
			whyusfirstblock11:
				"Пропонуємо різноманітні тарифні плани, що підходять для бізнесів будь-якого розміру.",
			whyusfirstblock2: "Підтримка 24/7",
			whyusfirstblock21:
				"Наші фахівці завжди готові допомогти вам у будь-який час дня і ночі.",
			whyusfirstblock3: "Інтеграція з CRM",
			whyusfirstblock31:
				"Наша IP-телефонія легко інтегрується з бізнес-системою CRM, що спрощує управління клієнтами.",
			whyusfirstblock4: "Економія витрат",
			whyusfirstblock41:
				"Використання IP-телефонії значно знижує витрати на зв'язок порівняно з традиційними телефонами.",
			whyussecondblockh2: "Навіщо потрібна",
			whyussecondblockp: "IP-телефонія клієнтам",
			whyussecondblockp2:
				"IP-телефонія — це сучасний спосіб комунікації, який забезпечує ефективний і зручний зв'язок через інтернет. Вона дозволяє компаніям залишатися на зв'язку з клієнтами та партнерами з будь-якої точки світу, використовуючи лише інтернет-з'єднання.",
			whyusthirdblockh2: "Як IP-телефонія допоможе оптимізувати бізнес",
			whyusthirdblock1: "Покращення комунікацій",
			whyusthirdblock11:
				"Зниження витрат на зв'язок порівняно з традиційною телефонією.",
			whyusthirdblock2: "Мобільність",
			whyusthirdblock22:
				"Можливість прийому дзвінків на будь-якому пристрої, що дозволяє працювати з будь-якої точки світу.",
			whyusthirdblock3: "Автоматизація процесів",
			whyusthirdblock33:
				"Автоматичні функції, такі як голосова пошта, переадресація дзвінків та інтеграція з CRM, спрощують і прискорюють роботу.",
			whyusthirdblock4: "Аналітика",
			whyusthirdblock44:
				"Детальна аналітика дзвінків допомагає відстежувати ефективність роботи команди та якість обслуговування клієнтів.",
			whyussliderh2: "Як IP-телефонія допоможе збільшити продажі",
			whyusslide1h3: "Швидкий відгук на запити клієнтів",
			whyusslide1p:
				"Забезпечує оперативну відповідь на дзвінки та запити, що підвищує рівень задоволеності клієнтів.",
			whyusslide2h3: "Покращення обслуговування клієнтів",
			whyusslide2p:
				"Забезпечує персоналізований підхід до кожного клієнта завдяки інтеграції з CRM.",
			whyusslide3h3: "Маркетингові можливості",
			whyusslide3p:
				"Використання call tracking для відстеження ефективності рекламних кампаній і оптимізації витрат на маркетинг.",
			whyusslide4h3: "Підвищення ефективності команди",
			whyusslide4p:
				"Можливість швидко та зручно комунікувати між собою підвищує ефективність роботи команди, що в свою чергу сприяє збільшенню продажів.",
			details_hide_text: "Приховати",
			details_link_text: "Детальніше",
			thanksText0: "Повернутись на головну",
			thanksText1: "Дякую!",
			thanksText2: "Ваша заявка прийнята",
			thanksText3: "Наші менеджери зв'яжуться з Вами найближчим часом!",
			formSubmitting: "Наші менеджери зв’яжуться з вами найближчим часом"
		},
	},
	en: {
		translation: {
			ofertapz: "Software Offer",
			ofertacomunicat: "Communications Services Offer",
			modalh1: "Sign up for free testing",
			modalinput: "@Telegram",
			modalinputerror: "Error!",
			modalinputerror2: "Something went wrong!",
			modalinputok: "Thank you! We will contact you soon!",
			modaldisclaimer: "Learn more about the free trial conditions",
			menuitem1: "Rates",
			menuitem2: "Services",
			menuitem3: "Why Us",
			menuitem4: "How to Connect",
			menuitem5: "Contacts",
			menuitem6: "Hotline",
			h1: "Reliable communication line through",
			h1__2: "IP telephony",
			firstsectioncontentheadersub: "Scale your business with professionally set up telephony",
			firstsectioncontentheadersub2: "Free trial period and discounts for new clients",
			firstsectioncontentbtn: "Contact Us",
			firstsectioncontentbtn2: "Services",
			secondsectionh2: "Our Services",
			secondsectionh2__p: "and business advantages",
			secondsectionh2__p2: "Our IP telephony services provide your business with broad opportunities to improve communications and optimize workflows.",
			secondsectionh2__p3: "We offer modern solutions that help reduce costs, increase efficiency, and ensure high-quality communication.",
			secondsectionh2__p4: "With our innovative technologies and flexible pricing plans, you can improve customer service, automate processes, and ensure the smooth operation of your team.",
			secondsectiontabsservices: "Main Benefits:",
			secondsectiontabs1: "IP Telephony",
			secondsectiontabs2: "Mobile Numbers",
			secondsectiontabs3: "CRM System",
			secondsectiontabs4: "Call Tracking",
			secondsectiontabs5: "AI Speech Analytics",
			secondsectiontabscontent1header: "IP Telephony",
			secondsectiontabscontent1header2: "Provides high-quality communication with the ability to store all history and recordings of client conversations.",
			secondsectiontabscontent1text1: "<strong>Cost Reduction</strong> on communication compared to traditional telephony.",
			secondsectiontabscontent1text11: "<strong>IP Telephony</strong> is a modern technology that allows voice calls to be transmitted over the internet or other IP networks. It ensures high-quality communication without the use of traditional phone lines. IP telephony is essential for optimizing business processes, reducing communication costs, and increasing employee mobility.",
			secondsectiontabscontent1text12: "Thanks to integration capabilities with CRM systems and other business applications, it helps improve customer service, manage calls more efficiently, and analyze their effectiveness. This makes IP telephony an indispensable tool for modern businesses.",
			secondsectiontabscontent1text2: "<strong>Mobility and Flexibility</strong> – the ability to use a phone number regardless of geographic location.",
			secondsectiontabscontent1text3: "<strong>Integration</strong> with other systems – CRM, virtual PBX, and others.",
			secondsectiontabscontent2header: "Mobile Numbers",
			secondsectiontabscontent2header2: "Phone numbers are used to make and receive calls over the internet.",
			secondsectiontabscontent2text11: "<strong>Mobile Numbers</strong> are phone numbers used to make and receive calls over the internet, ensuring mobility and flexibility in communication. They allow users to stay connected regardless of location, using any device with internet access.",
			secondsectiontabscontent2text12: "Mobile numbers are particularly useful for businesses, as they allow a single contact number to be retained during relocations, office expansions, or remote work, contributing to increased work efficiency and improved customer service.",
			secondsectiontabscontent2text1: "<strong>Availability:</strong> the ability to receive and make calls via IP telephony even in the absence of mobile coverage by using the internet.",
			secondsectiontabscontent2text2: "<strong>Mobility and Flexibility</strong> – the ability to use a phone number regardless of geographic location.",
			secondsectiontabscontent3header: "CRM System",
			secondsectiontabscontent3header2: "Automatically saves call data, records conversations, tracks communication history, and analyzes communication effectiveness.",
			secondsectiontabscontent3text11: "<strong>CRM System (Customer Relationship Management)</strong> is a tool for managing customer interactions that integrates with the company's phone system. It automatically saves call data, records conversations, tracks communication history, and analyzes communication effectiveness.",
			secondsectiontabscontent3text12: "Using a CRM system helps improve customer service, respond more quickly to requests and offers, improve internal business processes, and increase team productivity. This solution is key for companies looking to effectively manage customer relationships and boost sales.",
			secondsectiontabscontent3text1: "<strong>Call Integration:</strong> the ability to integrate IP telephony for automatic saving of call recordings and call data into the CRM.",
			secondsectiontabscontent3text2: "<strong>Convenient Management:</strong> automation and improvement of customer interactions through built-in IP telephony features (call recording, automatic call distribution).",
			secondsectiontabscontent4header: "Call Tracking",
			secondsectiontabscontent4header2: "A technology that allows tracking call sources and analyzing advertising effectiveness.",
			secondsectiontabscontent4text11: "<strong>Call Tracking</strong> is a technology that allows tracking and analyzing incoming phone calls. It helps determine call sources, such as advertising campaigns, websites, or other marketing channels.",
			secondsectiontabscontent4text12: "Using a CRM system helps improve customer service, respond more quickly to requests and offers, improve internal business processes, and increase team productivity. This solution is key for companies looking to effectively manage customer relationships and boost sales.",
			secondsectiontabscontent4text1: "<strong>Advertising Effectiveness Analysis:</strong> the ability to analyze the effectiveness of advertising campaigns and other call sources.",
			secondsectiontabscontent4text2: "<strong>Conversion Increase:</strong> understanding where calls come from helps optimize marketing strategies and improve customer service.",
			secondsectiontabscontent5header: "AI Speech Analytics",
			secondsectiontabscontent5header2: "A digital assistant that quickly processes data and provides useful insights based on it.",
			secondsectiontabscontent5text11: "<strong>AI-based Telephony</strong> is the future of business communications and a current competitive advantage. It not only provides high-quality communication and call information but also gives you a digital assistant that quickly processes data and provides useful insights. AI will become an indispensable assistant for sales, support, and even marketing departments.",
			secondsectiontabscontent5text12: "Thanks to integration capabilities with CRM systems and other business applications, it helps improve customer service, manage calls more efficiently, and analyze their effectiveness. This makes IP telephony an indispensable tool for modern businesses.",
			secondsectiontabscontent5text1: "<strong>Time-saving:</strong> Golden Line AI automatically creates text transcriptions of calls and short conversation summaries. Now, you don't need to spend hours listening to calls.",
			secondsectiontabscontent5text2: "<strong>Focusing on Key Points:</strong> due to limited time and a large volume of data, analysis is often selective, which can lead to the loss of important information. AI helps highlight key moments that deserve attention.",
			secondsectiontabscontent5text3: "<strong>Deal-making Recommendations:</strong> your digital assistant analyzes the conversation and provides practical advice on further actions to maximize the chances of closing a deal with the client.",
			thirdsectionh2: "Rates",
			thirdsectionh2p: "What we offer",
			thirdsectionp: "Rate",
			thirdsectionp1: "basic",
			thirdsectionp2: "advanced",
			thirdsectionp3: "premium",
			thirdsectionh2AI: "Speech Analytics",
			thirdsectionh2p1AI: "AI-based",
			thirdsectionh2p2AI: "Make the most of your calls with artificial intelligence – your reliable assistant in closing deals.",
			thirdsectionh2p3AI: "Today, AI skills provide an advantage, but tomorrow they will become essential for professional activities. Implement a tool that will improve the efficiency of your departments and significantly impact the growth of sales in your company today.",
			thirdsectionh2p4AI: "The main goal of Golden Line AI is to make your work and the activities of your employees easier and more productive.",
			thirdsectionblockp1: "Ideal for small companies or startups. It includes a basic set of voice communication functions, a limited number of calls, and basic support.",
			thirdsectionblockp2: "An optimal solution for medium-sized businesses that need more communication management capabilities and integration with other business systems.",
			thirdsectionblockp3: "Designed for large companies that need the maximum IP telephony capabilities, including unlimited calls and enhanced security features.",
			thirdsectionblockbtntext: "Learn More",
			foursectionh2: "How It Works",
			foursectionh2p: "Main devices for using IP telephony:",
			foursectionp1: "IP Phone",
			foursectionblockp1: "A special phone that connects to the internet and uses IP for voice transmission.",
			foursectionp2: "Internet Router",
			foursectionblockp2: "A device for connecting to the internet and creating a network for IP phones.",
			foursectionp3: "Computer or Mobile Device",
			foursectionblockp3: "Used for softphones, allowing calls to be made through special applications.",
			foursectionp4: "VoIP Gateway or Adapter",
			foursectionblockp4: "Converts the signal from traditional phones or connects IP telephony to regular telephone networks.",
			fivesectionh4: "Submit an Application",
			fivesectionh4p: "and get a free trial period",
			fivesectionplaceholder1: "Full Name",
			fivesectionplaceholder2: "Phone Number",
			fivesectionplaceholder3: "Email",
			fivesectionplaceholder4: "Your Business Name",
			fivesectioncontentbtn: "Submit",
			ratepageheaderbtnname1: "₴ 350",
			ratepageheaderbtnname11: " /month",
			ratepageheaderbtnname2: "₴ 600",
			ratepageheaderbtnname22: " /month",
			ratepageheaderbtnname3: "₴ 1600",
			ratepageheaderbtnname33: " /month",
			ratepageheaderbtnname: "Rate",
			ratepageheaderbtnnamerate1: "Basic",
			ratepageheaderbtnnamerate2: "Advanced",
			ratepageheaderbtnnamerate3: "Premium",
			ratepagetablestroke1: "Connection and Setup of Virtual PBX",
			ratepagetablestroke1_1: "Our specialists provide a quick system launch, professional setup tailored to specific business needs, and explain how to use Golden Line as conveniently and effectively as possible.",
			ratepagetablestroke2: "Connection of Own Phone Number",
			ratepagetablestroke2_1: "Golden Line's virtual PBX allows you to connect numbers that your company already uses",
			ratepagetablestroke3: "Number of Workplaces",
			ratepagetablestroke3_1: "This number determines how many employees can access telephony for making and receiving calls.",
			ratepagetablestroke4: "Call Recording",
			ratepagetablestroke4__1: "500 min",
			ratepagetablestroke4__2: "10 000 min",
			ratepagetablestroke4__3: "100 000 min",
			ratepagetablestroke4_1: "All agreements between managers and clients are recorded. Employees work more efficiently. It is much faster and easier to resolve disputes and train new employees.",
			ratepagetablestroke5: "Missed Call Prevention",
			ratepagetablestroke5_1: "You don't lose clients. All incoming requests are automatically recorded and monitored. You don't have forgotten or missed calls, even outside working hours and on weekends.",
			ratepagetablestroke51: "up to 3",
			ratepagetablestroke52: "up to 6",
			ratepagetablestroke53: "up to 10",
			ratepagetablestroke6: "Call History",
			ratepagetablestroke6_1: "You can see the full picture of employee communication with clients",
			ratepagetablestroke7: "Optimization of Outbound Call Costs",
			ratepagetablestroke7_1: "The system analyzes the direction of your call and automatically selects the most advantageous phone number (city, mobile, international) for making the call.",
			ratepagetablestroke8: "Time-based Call Reception Scenarios",
			ratepagetablestroke8_1: "You choose the most convenient option for handling calls considering the specifics of your business and the company's work schedule",
			ratepagetablestroke81: "+ multiple scenarios",
			ratepagetablestroke9: "Mobile Phone Numbers",
			ratepagetablestroke9_1: "Golden Line's virtual PBX allows you to connect new numbers from any mobile operator",
			ratepagetablestroke10: "City Phone Numbers",
			ratepagetablestroke10_1: "Golden Line's virtual PBX allows you to connect any city and international numbers",
			ratepagetablestroke11: "Employee Personal Account",
			ratepagetablestroke11_1: "You will have a personal account that stores data about all calls: total number, duration, call recordings, list of missed calls, detailed analytics, etc.",
			ratepagetablestroke12: "Free Calls Between Employees",
			ratepagetablestroke12_1: "Golden Line's virtual PBX works via the internet, so internal communication for all employees is free regardless of their location.",
			ratepagetablestroke121: "Number-based Identification",
			ratepagetablestroke122: "Client Name Identification",
			ratepagetablestroke123: "Expanded communication history with the client during the call",
			ratepagetablestroke13: "Access to Knowledge Base (Instructions)",
			ratepagetablestroke13_1: "Access to a centralized knowledge base facilitates the training of new employees, providing them with the necessary information for efficient work.",
			ratepagetablestroke14: "Call Parking",
			ratepagetablestroke14_1: "The ability to put an active call on hold and continue the conversation from another phone within the organization",
			ratepagetablestroke15: "Contacts",
			ratepagetablestroke15_1: "The ability to identify a client during the call.",
			ratepagetablestroke16: "Call Transfer",
			ratepagetablestroke16_1: "The ability to quickly direct the client to the required specialist, improving service quality and reducing query resolution time.",
			ratepagetablestroke17: "Call Pickup",
			ratepagetablestroke17_1: "The ability to quickly answer an incoming call when the main operator is busy or unavailable, ensuring uninterrupted service and minimizing client wait times.",
			ratepagetablestroke171: "1 scenario",
			ratepagetablestroke172: "Unlimited scenarios",
			ratepagetablestroke18: "Call Forwarding",
			ratepagetablestroke18_1: "The ability to forward calls to another number or device, ensuring continuous communication and availability, even when the main recipient is unavailable or busy.",
			ratepagetablestroke19: "Interactive Voice Menu – IVR",
			ratepagetablestroke19__2: "single-level",
			ratepagetablestroke19__3: "multilevel",
			ratepagetablestroke19_1: "Competent IVR menu setup means employees don't have to answer numerous similar incoming calls and constantly transfer callers to different employees.",
			ratepagetablestroke191: "5 lines",
			ratepagetablestroke20: "Automatic Call Distribution",
			ratepagetablestroke20__2: "script, queue",
			ratepagetablestroke20__3: "scenario, queue, queue with notification, advanced scenario",
			ratepagetablestroke20_1: "Significantly better distribution of customer calls to a group of employees rather than just one device. This makes it easier to handle calls, reduces response time, and decreases missed calls. Most importantly, employees stop passing mobile phones to each other, so you forget about phone chaos.",
			ratepagetablestroke201: "Enhanced",
			ratepagetablestroke202: "Maximum",
			ratepagetablestroke21: "Support",
			ratepagetablestroke21_2: "during working hours",
			ratepagetablestroke21_3: "within 1 hour",
			ratepagetablestroke21_1: "Quick resolution of technical issues and prompt assistance, ensuring the uninterrupted operation of Golden Line's virtual PBX",
			ratepagetablestroke22: "IP Phone Setup",
			ratepagetablestroke22_1: "Our specialists take care of the equipment setup to save you time, avoid stress from self-setup, guarantee proper operation from the first time, and ensure quick and seamless usage",
			ratepagetablestroke23: "Integration with Golden Line CRM",
			ratepagetablestroke23_1: "Combining telephony and CRM in one service improves customer service efficiency and quality. Conveniently integrated client data, automatic call history logging, quick access to information during a conversation, process automation, and increased employee productivity. This integration also simplifies analytics and customer relationship management.",
			ratepagetablestroke24: "API",
			ratepagetablestroke24_1: "Thanks to Golden Line's open APIs, telephony can easily be integrated with other business systems like CRM, ERP, or customer support systems. This allows for automatic call data storage, data exchange between systems, and improved overall business process efficiency",
			ratepagetablestroke25: "SMS/Viber Messaging",
			ratepagetablestroke25_1: "A quick and convenient way to send SMS messages to clients. Employees can send SMS to clients directly from their personal account. With just two clicks, you can send a message with a template or unique text.",
			ratepagetablestroke251: "Support",
			ratepagetablestroke252: "Support",
			ratepagetablestroke26: "Call Whisper",
			ratepagetablestroke26_1: "A function where a more experienced employee listens to a colleague's call in real-time and provides assistance if needed",
			ratepagetablestroke27: "Auto-dialer",
			ratepagetablestroke27_1: "The auto-dialer helps businesses automate customer communication processes, allowing for quick and efficient outreach to a large audience, notifying about important changes, promotions, special offers, conducting surveys, reminders, and reducing staffing costs.",
			ratepagetablestroke28: "Auto-dialer Setup",
			ratepagetablestroke28_1: "Our specialists will set up the auto-dialer to meet your specific business needs and explain how to use it as conveniently and efficiently as possible.",
			ratepagetablestroke29: "Number of Auto-dialer and Voice Robot Launches",
			ratepagetablestroke29_1: "This allows for the simultaneous handling of more calls, increases efficiency, reduces client wait times, and ensures continuous operation even under heavy loads, improving service quality and customer satisfaction.",
			ratepagetablestroke30: "Access to Auto-dialer Statistics",
			ratepagetablestroke30_1: "These data can be used to analyze the auto-dialer and voice robots to improve their performance and customer satisfaction. You can also analyze how effective the auto-dialer is in achieving set goals, such as customer engagement or sales growth",
			ratepagetablestroke31: "Migration from Other Telephony Systems (Settings Transfer)",
			ratepagetablestroke31_1: "This allows for a quick and painless transition to a new phone system, retaining all existing settings, numbers, and rules, minimizing downtime, reducing adaptation time, and maintaining business processes without changes.",
			ratepagetablestroke32: "Telephony Event Handler",
			ratepagetablestroke32_1: "This allows for automatic responses to various events, such as incoming calls, call completions, left voicemails, etc. It increases efficiency, automates workflows, and improves customer service by ensuring fast and accurate responses to events.",
			ratepagetablestroke33: "GetCall Callback Button for Website",
			ratepagetablestroke33_1: "GetCall is a smart widget for your website that offers the client a callback within 30 seconds and automatically connects them to a manager. It increases inquiries from the website by up to 50%",
			ratepagetablestroke34: "Integration with Third-Party CRM",
			ratepagetablestroke34_1: "Integration of Golden Line telephony with your CRM system provides access to: Client information during the call; Quick creation of a client card during incoming calls; 'Client-employee' link; One-click dialing from the client card in CRM; Client call history in the CRM system.",
			ratepagetablestroke35: "Personalized Voicemail System with Email Notification",
			ratepagetablestroke35_1: "Users can instantly receive notifications about new messages, even if they are out of the office or don't have access to the phone system.",
			ratepagetablestroke36: "Mobile Application",
			ratepagetablestroke36_1: "An app installed on employees' work mobile phones that provides the company with ready-made call statistics and call recordings. For a selected period of time, for a specific employee or group of employees (department).",
			ratepagetablestroke361: "with email",
			ratepagetablestroke362: "without email",
			ratepagetablestroke37: "One-click Call (Click to Call)",
			ratepagetablestroke37_1: "All phone numbers in the account and on websites become clickable, saving your managers time on dialing numbers",
			ratepagetablestroke38: "Forwarding Management from Personal Account",
			ratepagetablestroke38_1: "Allows users to independently and in real-time configure call forwarding to other numbers or devices, providing flexibility, convenience, quick response to situations, and increasing availability for clients.",
			ratepagetablestroke39: "Call Center Module",
			ratepagetablestroke39_1: "More options for handling calls: smart rules for distributing incoming calls, operator statuses, reports, and analytics on all calls, 'Supervisor' function, and more.",
			ratepagetablestroke40: "Conference/Video Call",
			ratepagetablestroke40_1: "Allows multiple people to communicate simultaneously, which is convenient for discussing issues in real-time. Also enables quick decision-making by involving all necessary participants in the discussion.",
			ratepagetablestroke41: "Webhook",
			ratepagetablestroke41_1: "Access to program code that allows you to track changes on one site and transfer this data to another site",
			ratepagetablestroke411: "Integration with Your Asterisk",
			ratepagetablestroke411_1: "If you already have your own Asterisk and want to integrate it, our specialists will be happy to implement this for you",
			ratepagetablestroke42: "Additional Services",
			ratepagetablestroke421: "Price",
			ratepagetablestroke43:
				"Additional external line (telephone SIP number) outside the tariff",
			ratepagetablestroke431:
				"An additional communication channel connected to the company for making or receiving calls. This allows receiving or making more calls simultaneously, which is especially useful for companies with a large number of incoming or outgoing calls. It allows the company to maintain more connections with external clients without the need to switch to another tariff plan.",
			ratepagetablestroke44:
				"Additional workplace outside the tariff",
			ratepagetablestroke441:
				"The ability to add more workplaces or users to telephony than provided in the tariff plan. This allows scaling the telephone infrastructure according to the needs of your company, for example, when expanding staff without the need to switch to another tariff plan.",
			ratepagetablestroke45: "SIM card slot rental",
			ratepagetablestroke451:
				"Mobile numbers are connected to the Virtual PBX using special equipment - a GSM gateway, into which SIM cards are installed. You can rent slots for SIM cards in our equipment or purchase your own gateway.",
			ratepagetablestroke46: "Voice message (greeting) recorded by a voice-over artist",
			ratepagetablestroke461:
				"A voice greeting from our team of professional voice-over artists creates an impression of professionalism and reliability when calling your company. A pleasant voice can provide important information or direct clients to the right department, saving your employees' time. Additionally, your client immediately receives information about the company's working hours, allowing them to know when to call back or expect a response. This reduces client frustration, improves their experience with the company, and reduces missed communication opportunities.",
			ratepagetablestroke47: "GetCall callback button on the website",
			ratepagetablestroke471:
				"GetCall is an intelligent widget for your website that offers the client a callback within 30 seconds and automatically connects them with a manager. It increases the number of inquiries from the website by up to 50%. Visitors become your clients 24/7, and the system automatically connects employees with clients at the exact specified time. The widget color adjusts to your website's color scheme.",
			ratepagetablestroke48: "SPAM monitoring of phone numbers",
			ratepagetablestroke481:
				"A solution for automatically checking your business's phone numbers against SPAM databases of popular monitoring systems. This allows: identifying instances where your phone numbers are listed as a source of SPAM (databases: GetContact, TrueCaller, Hiya, Xiaomi); <br>automatically checking phone numbers according to your schedule;<br>automatically replacing phone numbers that have been marked as SPAM;<br>increasing the number of successful calls to your clients.",
			ratepagetablestroke49: "Additional 10,000 minutes of phone call recording",
			ratepagetablestroke491:
				"The ability to increase the amount of phone call recording without changing the tariff plan",
			ratepagetablestroke50: "Auto-dial",
			ratepagetablestroke501:
				"Auto-dial helps businesses automate the process of contacting clients, allowing for quick and efficient outreach to a large audience, notifications about important changes, promotions, special offers, conducting surveys, reminders, and reducing personnel costs.",
			ratepagetablestroke51_: "Auto-dial tariffing (per second)",
			ratepagetablestroke51_1:
				"Our specialists will configure auto-dial according to your business needs and explain how to use it as conveniently and efficiently as possible. Payment for tariffing is per second.",
			ratepagetablestroke52_: "Speech analytics",
			ratepagetablestroke52_1:
				"This is an innovative method of processing voice data by artificial intelligence, aimed at detecting, classifying, and extracting valuable information from phone conversations. Converting sound into text, analyzing the conversation by artificial intelligence, recognizing the language of the interlocutors, analyzing the phone conversation. This advanced high-tech tool automates the analysis of phone conversations, turning them into structured data.",
				ratepagetableprice1: "40 ₴/month",
ratepagetableprice2: "100 ₴/month",
ratepagetableprice3: "150 ₴/month",
ratepagetableprice4: "from 500 ₴",
ratepagetableprice5: "100 ₴/month",
ratepagetableprice6: "50 ₴",
ratepagetableprice7: "from 50 ₴",
ratepagetableprice8: "from 1000 ₴",
ratepagetableprice9: "from 0.50 ₴/minute",
ratepagetableprice10: "from 1.20 ₴/minute",
ratepagetableprice11: "40 ₴/internal line",

			ratepagetablestroke53_: "TLS encryption",
			ratepagetablestroke53_1:
				"Enhanced encryption using the TLS (Transport Layer Security) protocol provides maximum data security, confidentiality, and protection against all types of attacks. Complies with GDPR and HMAC standards.",
			howtoh2: "How to Connect",
			howtoh3: "Virtual PBX Golden Line",
			howto1h4: "Choose a Rate",
			howto1p1: "Compare the different rate plans offered in the Rates tab. Consider the number of users, required features, and your company's budget. Choose the rate that best suits your needs.",
			howto2h4: "Create an Account",
			howto1p2: "Contact us and create an account. Log in to the system and configure the basic parameters: add employees, create virtual numbers, and set up basic features.",
			howto3h4: "Connect the Equipment",
			howto1p3: "Connect the necessary equipment, such as IP phones, VoIP gateways, or adapters. Make sure all devices are set up correctly and connected to the internet.",
			howto4h4: "Train Employees",
			howto1p4: "Conduct test calls to check the system's operation. Provide employees with all the necessary instructions for using the new system.",
			whyusheaderh2: "Learn",
			whyusheaderp: "Why Choose Us",
			whyusfirstblock1: "Flexible Rate Plans",
			whyusfirstblock11: "We offer various rate plans that suit businesses of any size.",
			whyusfirstblock2: "24/7 Support",
			whyusfirstblock21: "Our specialists are always ready to help you at any time of the day or night.",
			whyusfirstblock3: "CRM Integration",
			whyusfirstblock31: "Our IP telephony easily integrates with your CRM, simplifying customer management.",
			whyusfirstblock4: "Cost Savings",
			whyusfirstblock41: "Using IP telephony significantly reduces communication costs compared to traditional phones.",
			whyussecondblockh2: "Why Clients Need",
			whyussecondblockp: "IP Telephony",
			whyussecondblockp2: "IP telephony is a modern way of communication that provides effective and convenient communication over the internet. It allows companies to stay connected with clients and partners from anywhere in the world, using only an internet connection.",
			whyusthirdblockh2: "How IP Telephony Helps Optimize Business",
			whyusthirdblock1: "Improved Communications",
			whyusthirdblock11: "Reduced communication costs compared to traditional telephony.",
			whyusthirdblock2: "Mobility",
			whyusthirdblock22: "The ability to receive calls on any device, allowing work from anywhere in the world.",
			whyusthirdblock3: "Process Automation",
			whyusthirdblock33: "Automatic functions such as voicemail, call forwarding, and CRM integration simplify and speed up work.",
			whyusthirdblock4: "Analytics",
			whyusthirdblock44: "Detailed call analytics helps track team performance and customer service quality.",
			whyussliderh2: "How IP Telephony Helps Increase Sales",
			whyusslide1h3: "Quick Response to Client Requests",
			whyusslide1p: "Ensures prompt responses to calls and requests, increasing customer satisfaction.",
			whyusslide2h3: "Improved Customer Service",
			whyusslide2p: "Ensures a personalized approach to each client thanks to CRM integration.",
			whyusslide3h3: "Marketing Opportunities",
			whyusslide3p: "Using call tracking to monitor the effectiveness of advertising campaigns and optimize marketing costs.",
			whyusslide4h3: "Increased Team Efficiency",
			whyusslide4p: "The ability to communicate quickly and easily between team members increases work efficiency, which in turn contributes to increased sales.",
			details_hide_text: "Hide",
			details_link_text: "Learn More",
			thanksText0: "Return to Home",
			thanksText1: "Thank You!",
			thanksText2: "Your application has been received",
			thanksText3: "Our managers will contact you soon!",
			formSubmitting: "Our managers will contact you as soon as possible",
		},
	},
	// Other languages...
};

i18n
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		resources,
		fallbackLng: "ua", // Украинский как язык по умолчанию
		detection: {
			order: ["cookie", "localStorage", "htmlTag", "path", "subdomain"],
			caches: ["cookie"],
		},
		interpolation: {
			escapeValue: false, // React уже экранирует значения
		},
	});

export default i18n;
