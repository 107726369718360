import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../Button/Button";
import "./Modal.scss";
import x from './images/x.png';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function Modal({ isOpen, onClose }) {
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Full Name Validation
  const validateFullName = (name) => {
    const isValid = /^[a-zA-Zа-яА-ЯіїєІЇЄ\s]+$/.test(name);
    return isValid && name.trim().split(" ").length >= 2;
  };

  // Email Validation
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  // Phone Number Validation
  const handlePhoneChange = (value) => {
    if (!value || value.length < 10) {
      setPhoneError(t("invalidPhone"));
    } else {
      setPhoneError("");
      setPhoneNumber(value);
    }
  };

  // General Form Validation
  useEffect(() => {
    const isFormValid =
      fullName.trim() &&
      phoneNumber.trim() &&
      validateEmail(email) &&
      !phoneError &&
      !fullNameError;
    setIsFormValid(isFormValid);
  }, [fullName, phoneNumber, email, phoneError, fullNameError]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting || !isFormValid) return;

    setIsSubmitting(true);

    try {
      const response = await fetch("/sendToTelegram2.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fullName,
          phoneNumber,
          email,
          businessName,
        }),
      });

      const responseData = await response.json();

      if (response.ok) {
        setErrorMessage("");
        setSuccessMessage(t("modalinputok"));
        setFullName("");
        setPhoneNumber("");
        setEmail("");
        setBusinessName("");
        navigate("/thanks");
      } else {
        setErrorMessage(t("modalinputerror2"));
        setSuccessMessage("");
      }
    } catch (error) {
      setErrorMessage(t("modalinputerror2"));
      setSuccessMessage("");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal open">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          <img src={x} alt="Close" />
        </span>
        {!successMessage && !errorMessage && (
          <form onSubmit={handleSubmit} className="formModal">
            <h2>{t("modalh1")}</h2>
            <div className="formModal__inner">
              <input
                type="text"
                placeholder={t("fivesectionplaceholder1")}
                value={fullName}
                onChange={(e) => {
                  setFullName(e.target.value);
                  if (!validateFullName(e.target.value)) {
                    setFullNameError(t("invalidFullName"));
                  } else {
                    setFullNameError("");
                  }
                }}
                className={fullNameError ? "input-error" : ""}
                autoComplete="off"
                required
              />
              <PhoneInput
                country={"ua"}
                value={phoneNumber}
                onChange={handlePhoneChange}
                inputProps={{
                  name: "phone",
                  required: true,
                }}
                containerClass={phoneError ? "input-error" : ""}
              />
              <input
                type="email"
                placeholder={t("fivesectionplaceholder3")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={!validateEmail(email) && email ? "input-error" : ""}
                autoComplete="off"
                required
              />
              <input
                type="text"
                placeholder={t("fivesectionplaceholder4")}
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
                autoComplete="off"
              />
              <button
                type="submit"
                className="reset-button-styles"
                disabled={!isFormValid || isSubmitting}
              >
                {isSubmitting ? t("formSubmitting") : <ButtonComponent text={t("fivesectioncontentbtn")} />}
              </button>
            </div>
          </form>
        )}
        {successMessage && (
          <div className="success">
            <h2>{t("modalSuccessTitle")}</h2>
            <p>{successMessage}</p>
          </div>
        )}
        {errorMessage && (
          <div className="error">
            <h2>{t("modalinputerror")}</h2>
            <p>{errorMessage}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Modal;
