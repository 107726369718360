import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import "./Header.scss";
import logo from "./images/logo.svg";
import telegram from "./images/telegram-black.svg";
import phone from "./images/phone-black.svg";
import telegram2 from "./images/telegram.svg";
import phone2 from "./images/phone.svg";
import email from "./images/email.svg";
import emaily from "./images/email-y.svg";
import lifecell from "./images/lifecell.svg";
import kyivstar from "./images/kyivstar.svg";
import vodafone from "./images/vodafone.svg";

function Header() {
  const { t } = useTranslation();
  const location = useLocation();

  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState({
    desktop: false,
    tablet: false,
    mobile: false,
  });

  const [isBurgerOpen775, setIsBurgerOpen775] = useState(false);
const [isBurgerOpen440, setIsBurgerOpen440] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const toggleDropdown = (version) => {
    setIsDropdownOpen((prevState) => ({
      ...prevState,
      [version]: !prevState[version],
    }));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const targetElement = event.target;
      const dropdownButtons = document.querySelectorAll(
        ".header__content-btns__phone, .header__content-tablet__btns__phone, .header__content-tablet__btns-mob__top__phone"
      );
      const dropdownMenus = document.querySelectorAll(
        ".dropdown, .dropdownTablet, .dropdownMob"
      );

      let isClickOutside = true;
      dropdownButtons.forEach((button) => {
        if (button.contains(targetElement)) {
          isClickOutside = false;
        }
      });
      dropdownMenus.forEach((menu) => {
        if (menu.contains(targetElement)) {
          isClickOutside = false;
        }
      });

      if (isClickOutside) {
        setIsDropdownOpen({ desktop: false, tablet: false, mobile: false });
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const disableScroll = () => {
    document.body.style.overflow = "hidden"; // Отключаем прокрутку
  };
  
  const enableScroll = () => {
    document.body.style.overflow = ""; // Включаем прокрутку
  };

  const toggleBurger775 = () => {
    setIsBurgerOpen775(!isBurgerOpen775);
    setIsMenuOpen(false);
    if (!isBurgerOpen775) {
      disableScroll();
    } else {
      enableScroll();
    }
  };
  
  const toggleBurger440 = () => {
    setIsBurgerOpen440(!isBurgerOpen440);
    setIsMenuOpen(false);
    if (!isBurgerOpen440) {
      disableScroll();
    } else {
      enableScroll();
    }
  };
  
  useEffect(() => {
    if (!isBurgerOpen775 && !isBurgerOpen440) {
      enableScroll();
    }
  }, [isBurgerOpen775, isBurgerOpen440]);
  

  return (
    <header className={isScrolled ? "scrolled" : ""}>
      <div className="container">
        <div className="header__content">
          <div className="header__content-urls">
            <div className="header__content-urls__logo">
              <NavLink to="/">
                <img src={logo} alt="Golden Line" />
              </NavLink>
            </div>
            <div className="header__content-urls__menu">
              <nav>
                <LanguageSwitcher />
                <NavLink
                  to="/rate"
                  className={({ isActive }) => (isActive ? "active" : "")}
                  onClick={handleMenuClose}
                >
                  {t("menuitem1")}
                </NavLink>
                <NavLink
                  to="/services"
                  className={({ isActive }) => (isActive ? "active" : "")}
                  onClick={handleMenuClose}
                >
                  {t("menuitem2")}
                </NavLink>
                <NavLink
                  to="/why-us"
                  className={({ isActive }) => (isActive ? "active" : "")}
                  onClick={handleMenuClose}
                >
                  {t("menuitem3")}
                </NavLink>
                <NavLink
                  to="/how-to"
                  className={({ isActive }) => (isActive ? "active" : "")}
                  onClick={handleMenuClose}
                >
                  {t("menuitem4")}
                </NavLink>
                <NavLink
                  to="/contacts"
                  className={({ isActive }) => (isActive ? "active" : "")}
                  onClick={handleMenuClose}
                >
                  {t("menuitem5")}
                </NavLink>
              </nav>
            </div>
          </div>
          <div className="header__content-btns">
            <button className="header__content-btns__email">
              <a href="mailto:info@goldenline.in.ua">
                <img src={email} alt="E-Mail" />
              </a>
            </button>
            <button className="header__content-btns__telegram">
              <a href="https://t.me/test">
                <img src={telegram} alt="Telegram" />
              </a>
            </button>
            <button
              className="header__content-btns__phone"
              onClick={() => toggleDropdown("desktop")}
            >
              <img src={phone} alt="Phone" />
              <span>{t("menuitem6")}</span>
              <span>
                <svg
                  width="8"
                  height="13"
                  viewBox="0 0 8 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.687012 12.313L6.31304 6.64632L0.687012 1.31299"
                    stroke="#1A1A19"
                    strokeWidth="1.2"
                  />
                </svg>
              </span>
            </button>

            {/* Выпадающее меню для десктопа */}
            {isDropdownOpen.desktop && (
              <div className="dropdown">
                <ul>
                  <li>
                    <a href="tel:+380638899090">
                      <img src={lifecell} alt="Lifecell" />
                      +38 063 889 90 90
                    </a>
                  </li>
                  <li>
                    <a href="tel:+380678899090">
                      <img src={kyivstar} alt="Kyivstar" />
                      +38 067 889 90 90
                    </a>
                  </li>
                  <li>
                    <a href="tel:+380508899090">
                      <img src={vodafone} alt="Vodafone" />
                      +38 050 889 90 90
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className="header__content-tablet">
            <div className="header__content-tablet__logo">
              <NavLink to="/">
                <img src={logo} alt="Golden Line" />
              </NavLink>
              <LanguageSwitcher />
            </div>
            <div className="header__content-tablet__btns">
              <button className="header__content-tablet__btns__email">
                <a href="mailto:info@goldenline.in.ua">
                  <img src={email} alt="E-Mail" />
                </a>
              </button>
              <button className="header__content-tablet__btns__telegram">
                <a href="https://t.me/test">
                  <img src={telegram} alt="Telegram" />
                </a>
              </button>
              <button
                className="header__content-tablet__btns__phone"
                onClick={() => toggleDropdown("tablet")}
              >
                <img src={phone} alt="Phone" />
                <span>{t("menuitem6")}</span>
                <span>
                  <svg
                    width="8"
                    height="13"
                    viewBox="0 0 8 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.687012 12.313L6.31304 6.64632L0.687012 1.31299"
                      stroke="#1A1A19"
                      strokeWidth="1.2"
                    />
                  </svg>
                </span>
              </button>
              <div className="header__burger-775">
  <div
    className={`burger-icon ${isBurgerOpen775 ? "open" : ""}`}
    onClick={toggleBurger775}
  >
    <span className="bar1"></span>
    <span className="bar2"></span>
  </div>
  <div className={`burger-menu-775 ${isBurgerOpen775 ? "open" : ""}`}>
    <nav>
      <NavLink to="/rate" onClick={toggleBurger775}>
        {t("menuitem1")}
      </NavLink>
      <NavLink to="/services" onClick={toggleBurger775}>
        {t("menuitem2")}
      </NavLink>
      <NavLink to="/why-us" onClick={toggleBurger775}>
        {t("menuitem3")}
      </NavLink>
      <NavLink to="/how-to" onClick={toggleBurger775}>
        {t("menuitem4")}
      </NavLink>
      <NavLink to="/contacts" onClick={toggleBurger775}>
        {t("menuitem5")}
      </NavLink>
    </nav>
  </div>
</div>

              {/* Выпадающее меню для планшета */}
              {isDropdownOpen.tablet && (
                <div className="dropdownTablet">
                  <ul>
                    <li>
                      <a href="tel:+380638899090">
                        <img src={lifecell} alt="Lifecell" />
                        +38 063 889 90 90
                      </a>
                    </li>
                    <li>
                      <a href="tel:+380678899090">
                        <img src={kyivstar} alt="Kyivstar" />
                        +38 067 889 90 90
                      </a>
                    </li>
                    <li>
                      <a href="tel:+380508899090">
                        <img src={vodafone} alt="Vodafone" />
                        +38 050 889 90 90
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className="header__content-tablet__btns-mob">
              <div className="header__content-tablet__btns-mob__top">
                <button className="header__content-tablet__btns-mob__top__email">
                  <a href="mailto:info@goldenline.in.ua">
                    <img src={emaily} alt="E-Mail" />
                  </a>
                </button>
                <button className="header__content-tablet__btns-mob__top__telegram">
                  <a href="https://t.me/test">
                    <img src={telegram2} alt="Telegram" />
                  </a>
                </button>
                <button
                  className="header__content-tablet__btns-mob__top__phone"
                  onClick={() => toggleDropdown("mobile")}
                >
                  <img src={phone2} alt="Phone" />
                  <span>{t("menuitem6")}</span>
                  <span>
                    <svg
                      width="8"
                      height="13"
                      viewBox="0 0 8 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.687012 12.313L6.31304 6.64632L0.687012 1.31299"
                        stroke="#FDD85A"
                        strokeWidth="1.2"
                      />
                    </svg>
                  </span>
                </button>
              </div>
              <div className="header__content-tablet__btns-mob__bottom">
              <div className="header__burger-440">
  <div
    className={`burger-icon ${isBurgerOpen440 ? "open" : ""}`}
    onClick={toggleBurger440}
  >
    <span className="bar1"></span>
    <span className="bar2"></span>
  </div>
  <div className={`burger-menu-440 ${isBurgerOpen440 ? "open" : ""}`}>
    <nav>
      <NavLink to="/rate" onClick={toggleBurger440}>
        {t("menuitem1")}
      </NavLink>
      <NavLink to="/services" onClick={toggleBurger440}>
        {t("menuitem2")}
      </NavLink>
      <NavLink to="/why-us" onClick={toggleBurger440}>
        {t("menuitem3")}
      </NavLink>
      <NavLink to="/how-to" onClick={toggleBurger440}>
        {t("menuitem4")}
      </NavLink>
      <NavLink to="/contacts" onClick={toggleBurger440}>
        {t("menuitem5")}
      </NavLink>
    </nav>
  </div>
</div>

              </div>
              {isDropdownOpen.mobile && (
                <div className="dropdownMob">
                  <ul>
                    <li>
                      <a href="tel:+380638899090">
                        <img src={lifecell} alt="Lifecell" />
                        +38 063 889 90 90
                      </a>
                    </li>
                    <li>
                      <a href="tel:+380678899090">
                        <img src={kyivstar} alt="Kyivstar" />
                        +38 067 889 90 90
                      </a>
                    </li>
                    <li>
                      <a href="tel:+380508899090">
                        <img src={vodafone} alt="Vodafone" />
                        +38 050 889 90 90
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
       
        </div>
      </div>
    </header>
  );
}

export default Header;
