import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import "./RatePageTable.scss";
import check from "./images/check.svg";
import x from "./images/x.png";

const rows = [
	{
		textKey: "ratepagetablestroke1",
		textKey2: "ratepagetablestroke1_1",
		cols: [true, true, true],
	},
	{
		textKey: "ratepagetablestroke2",
		textKey2: "ratepagetablestroke2_1",
		cols: ["ratepagetablestroke51", "ratepagetablestroke52", "ratepagetablestroke53"],
	},
	{
		textKey: "ratepagetablestroke3",
		textKey2: "ratepagetablestroke3_1",
		cols: ["5", "10", "15"],
	},
	{
		textKey: "ratepagetablestroke4",
		textKey2: "ratepagetablestroke4_1",
		cols: ["ratepagetablestroke4__1", "ratepagetablestroke4__2", "ratepagetablestroke4__3"],
	},
	{
		textKey: "ratepagetablestroke5",
		textKey2: "ratepagetablestroke5_1",
		cols: [true, true, true],
	},
	{
		textKey: "ratepagetablestroke6",
		textKey2: "ratepagetablestroke6_1",
		cols: [true, true, true],
	},
	{
		textKey: "ratepagetablestroke7",
		textKey2: "ratepagetablestroke7_1",
		cols: [true, true, true],
	},
	{
		textKey: "ratepagetablestroke8",
		textKey2: "ratepagetablestroke8_1",
		cols: [true, true, true],
	},
	{
		textKey: "ratepagetablestroke9",
		textKey2: "ratepagetablestroke9_1",
		cols: [true, true, true],
	},
	{
		textKey: "ratepagetablestroke10",
		textKey2: "ratepagetablestroke10_1",
		cols: [true, true, true],
	},
	{
		textKey: "ratepagetablestroke11",
		textKey2: "ratepagetablestroke11_1",
		cols: [true, true, true],
	},
	{
		textKey: "ratepagetablestroke12",
		textKey2: "ratepagetablestroke12_1",
		cols: [true, true, true],
	},
	{
		textKey: "ratepagetablestroke21",
		textKey2: "ratepagetablestroke21_1",
		cols: ["ratepagetablestroke21_2", "ratepagetablestroke21_2", "ratepagetablestroke21_3"],
	},
	{
		textKey: "ratepagetablestroke13",
		textKey2: "ratepagetablestroke13_1",
		cols: [true, true, true],
	},
	{
		textKey: "ratepagetablestroke14",
		textKey2: "ratepagetablestroke14_1",
		cols: [true, true, true],
	},
	{
		textKey: "ratepagetablestroke15",
		textKey2: "ratepagetablestroke15_1",
		cols: ["ratepagetablestroke121", "ratepagetablestroke122", "ratepagetablestroke123"],
	},
	{
		textKey: "ratepagetablestroke37",
		textKey2: "ratepagetablestroke37_1",
		cols: [true, true, true],
	},
	{
		textKey: "ratepagetablestroke16",
		textKey2: "ratepagetablestroke16_1",
		cols: [true, true, true],
	},
	{
		textKey: "ratepagetablestroke17",
		textKey2: "ratepagetablestroke17_1",
		cols: [false, true, true],
	},
	{
		textKey: "ratepagetablestroke18",
		textKey2: "ratepagetablestroke18_1",
		cols: [false, true, true],
	},
	{
		textKey: "ratepagetablestroke19",
		textKey2: "ratepagetablestroke19_1",
		cols: [false, "ratepagetablestroke19__2", "ratepagetablestroke19__3" ],
	},
	{
		textKey: "ratepagetablestroke20",
		textKey2: "ratepagetablestroke20_1",
		cols: [false, "ratepagetablestroke20__2", "ratepagetablestroke20__3"],
	},

	{
		textKey: "ratepagetablestroke22",
		textKey2: "ratepagetablestroke22_1",
		cols: [false, true, true],
	},
	{
		textKey: "ratepagetablestroke23",
		textKey2: "ratepagetablestroke23_1",
		cols: [false, true, true],
	},
	{
		textKey: "ratepagetablestroke24",
		textKey2: "ratepagetablestroke24_1",
		cols: [false, true, true],
	},
	{
		textKey: "ratepagetablestroke25",
		textKey2: "ratepagetablestroke25_1",
		cols: [false, true, true],
	},
	{
		textKey: "ratepagetablestroke26",
		textKey2: "ratepagetablestroke26_1",
		cols: [false, true, true],
	},
	{
		textKey: "ratepagetablestroke27",
		textKey2: "ratepagetablestroke27_1",
		cols: [false, "ratepagetablestroke171", "ratepagetablestroke172"],
	},
	{
		textKey: "ratepagetablestroke28",
		textKey2: "ratepagetablestroke28_1",
		cols: [false, true, true],
	},
	{
		textKey: "ratepagetablestroke29",
		textKey2: "ratepagetablestroke29_1",
		cols: [false, "ratepagetablestroke191", "ratepagetablestroke191"],
	},
	{
		textKey: "ratepagetablestroke30",
		textKey2: "ratepagetablestroke30_1",
		cols: [false, "ratepagetablestroke201", "ratepagetablestroke202"],
	},
	{
		textKey: "ratepagetablestroke31",
		textKey2: "ratepagetablestroke31_1",
		cols: [false, false, true],
	},

	{
		textKey: "ratepagetablestroke33",
		textKey2: "ratepagetablestroke33_1",
		cols: [false, true, true],
	},
	{
		textKey: "ratepagetablestroke34",
		textKey2: "ratepagetablestroke34_1",
		cols: [false, false, true],
	},
	
	{
		textKey: "ratepagetablestroke36",
		textKey2: "ratepagetablestroke36_1",
		cols: [false, false, true],
	},
	
	{
		textKey: "ratepagetablestroke38",
		textKey2: "ratepagetablestroke38_1",
		cols: [false, false, true],
	},
	
	{
		textKey: "ratepagetablestroke41",
		textKey2: "ratepagetablestroke41_1",
		cols: [false, false, true],
	},
	{
		textKey: "ratepagetablestroke411",
		textKey2: "ratepagetablestroke411_1",
		cols: [false, false, true],
	},
];

const additionalRows = [
	{
		service: "ratepagetablestroke43",
		description: "ratepagetablestroke431",
		price: "ratepagetableprice1",
	},
	{
		service: "ratepagetablestroke44",
		description: "ratepagetablestroke441",
		price: "ratepagetableprice2",
	},
	{
		service: "ratepagetablestroke45",
		description: "ratepagetablestroke451",
		price: "ratepagetableprice3",
	},
	{
		service: "ratepagetablestroke46",
		description: "ratepagetablestroke461",
		price: "ratepagetableprice4",
	},
	{
		service: "ratepagetablestroke47",
		description: "ratepagetablestroke471",
		price: "ratepagetableprice5",
	},
	{
		service: "ratepagetablestroke48",
		description: "ratepagetablestroke481",
		price: "ratepagetableprice6",
	},
	{
		service: "ratepagetablestroke49",
		description: "ratepagetablestroke491",
		price: "ratepagetableprice7",
	},
	{
		service: "ratepagetablestroke50",
		description: "ratepagetablestroke501",
		price: "ratepagetableprice8",
	},
	{
		service: "ratepagetablestroke51_",
		description: "ratepagetablestroke51_1",
		price: "ratepagetableprice9",
	},
	{
		service: "ratepagetablestroke52_",
		description: "ratepagetablestroke52_1",
		price: "ratepagetableprice10",
	},
	{
		service: "ratepagetablestroke53_",
		description: "ratepagetablestroke53_1",
		price: "ratepagetableprice11",
	},
];

function RatePageTable({ selectedColumn, isDesktop }) {
	const { t } = useTranslation();

	return (
		<>
			<table className="table-container">
				<tbody>
					{rows.map((row, rowIndex) => (
						<tr key={rowIndex} className="table-row">
							<td>
								<p>{t(row.textKey)}</p>
								<div className="hover-block"><Trans>{t(row.textKey2)}</Trans></div>
							</td>
							{row.cols.map((col, colIndex) => (
								<td
									key={colIndex}
									className={
										isDesktop || colIndex === selectedColumn
											? "selected"
											: "hidden"
									}
								>
									{typeof col === "boolean" ? (
										<img src={col ? check : x} alt="" />
									) : col.img ? (
										<>
											<img src={check} alt="" />
											<span>{t(col.text)}</span>
										</>
									) : (
										<p>{t(col)}</p>
									)}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>

			<table className="additional-table">
				<thead>
					<tr>
						<th>{t("ratepagetablestroke42")}</th>
						<th></th>
						<th>{t("ratepagetablestroke421")}</th>
					</tr>
				</thead>
				<tbody>
					{additionalRows.map((row, index) => (
						<DescriptionRow key={index} row={row} t={t} />
					))}
				</tbody>
			</table>
		</>
	);
}

function DescriptionRow({ row }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const fullText = t(row.description);
  const maxLength = 130;
  const shouldTruncate = fullText.length > maxLength;
  const displayedText = shouldTruncate && !isExpanded 
      ? `${fullText.substring(0, maxLength)}...` 
      : fullText;

  return (
      <tr>
          <td>
              <div className="service-name">{t(row.service)}</div>
          </td>
          <td>
              <div
                  className="service-description"
                  dangerouslySetInnerHTML={{ __html: displayedText }}
              />
              {shouldTruncate && (
                  <div>
                      <button className="details-link" onClick={toggleExpand}>
                          {isExpanded ? t("details_hide_text") : t("details_link_text")}
                      </button>
                  </div>
              )}
          </td>
          <td className="price-column"><div>{t(row.price)}</div></td>
      </tr>
  );
}


export default RatePageTable;
